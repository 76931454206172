export default {
	state: {
		isDemo: false,
		folder: "",
		ui: 1,
		id: 1,

		name: "cabik",
		fullname: "CABIK S.R.L.",
		phone: "011 4481-9822",
		email: "pedidos@cabik.com.ar",
		whatsapp: "5491144819822",
		fiscalkey: "12345672890",
		address: "26 de Abril 4150/56",
		city: "Ituzaingo",
		state: "Buenos Aires",
		zip_code: "1714",
		open_hours: [
			{day: 'Lunes', from: '8:00', to: '17:00' },
			{day: 'Martes', from: '8:00', to: '17:00' },
			{day: 'Miércoles', from: '8:00', to: '17:00' },
			{day: 'Jueves', from: '8:00', to: '17:00' },
			{day: 'Viernes', from: '8:00', to: '17:00' },
			{day: 'Sábado', from: null, to: null },
			{day: 'Domingo', from: null, to: null },
		],
		
		isGuestEnabled: false, // Habilito acceso a invitados (usuarios no registrados)
		enablePayments: false, // Habilita modulo de pagos para enviar el pedido.
		enableSurveys: true, // Habilita las encuestas en la app.
		orderMinimumAmount: 20000, // Mínimo para compras. TODO: esto debería obtenerse desde una API.
		freeDeliveryFrom: 10000,
		shipmentAmount: 3800,
		showStockIconOnOrder: true,
		// defaultUrlImages: "https://cbktest.osapp.com.ar/doc/prods/",

		default_showTable: false, // modo de vista por defecto al iniciar por primera vez
		default_sendTo: 2,
		
		bankData: [
			{ name: "BANK NAME 1", branch: "999", cbu: "1234567890123456789012345", accountNumber: "012-34567/89", alias: "BANK1.ACCOUNT.ALIAS" },
			{ name: "BANK NAME 2", branch: "999", cbu: "1234567890123456789012345", accountNumber: "012-34567/89", alias: "BANK2.ACCOUNT.ALIAS" },
			{ name: "BANK NAME 3", branch: "999", cbu: "1234567890123456789012345", accountNumber: "012-34567/89", alias: "BANK3.ACCOUNT.ALIAS" },
			{ name: "BANK NAME 4", branch: "999", cbu: "1234567890123456789012345", accountNumber: "012-34567/89", alias: "BANK4.ACCOUNT.ALIAS" },
		],

		bankFaceToFace: [
			// {name: "MACRO", type: "Recaudación", number: "Convenio 74024 + CUIT de cliente", terminal: "SI (efectivo y cheque al día)", cashier: "SI (efectivo y cheque al día"},
			// {name: "GALICIA", type: "Cobranza integrada", number: "Convenio 3434 + CUIT de cliente", terminal: "SI (efectivo y cheque al día)", cashier: "SI (efectivo y cheque al día)"},
		],
		bankFaceToFaceAdvice: [
			// {name: "PATAGONIA", type: "Cuenta Corriente", number: "056-56000565-000", terminal: "SI (sólo efectivo)", cashier: "SI (sólo efectivo)"},
			// {name: "SANTANDER", type: "Cuenta Corriente", number: "066-001281/6", terminal: "SI (sólo efectivo)", cashier: "SI (sólo efectivo)"},
			// {name: "NACIÓN", type: "Cuenta Corriente", number: "152-00079/44", terminal: "SI (sólo efectivo)", cashier: "SI (sólo efectivo)"},
			// {name: "CÓRDOBA", type: "Cuenta Corriente", number: "919-6664/6", terminal: "SI (sólo efectivo)", cashier: "SI (sólo efectivo)"}
		],
		
		menuLinks: [
			// agregar un 0 en el array hideForRole para quitar la opción del menú a todos los roles
			{ text: "Home", icon: "mdi-home", route: "/", hideForRole: [] },
			{ text: "Productos", icon: "mdi-view-dashboard", route: "/productos", hideForRole: [] },
			// { text: "Productos Fixed",				icon: "mdi-view-dashboard",				route: "/productosff", hideForRole: [] },
			// { text: "Aplicaciones", icon: "mdi-puzzle", route: "/aplicaciones", hideForRole: [] },
			{ text: "Novedades", icon: "mdi-newspaper-variant", route: "/novedades", hideForRole: [0] },
			{ text: "Mi Carrito", icon: "mdi-cart", route: "/carrito", hideForRole: [] },
			{ text: "Mis Pedidos", icon: "mdi-cart-check", route: "/pedidos", hideForRole: [] },
			{ text: "Folletos Digitales", icon: "mdi-star", route: "/flyers", hideForRole: [2, 9] },
			{ text: "Catálogos", icon: "mdi-book-open-variant", route: "/catalogos", hideForRole: [2, 9] },
			{ text: "Descargas", icon: "mdi-download", route: "/descargas", hideForRole: [2, 9] },
			{
				text: "Autogestión",
				icon: "mdi-file-document",
				hideForRole: [2, 9],
				children: [
					// { text: "Cuenta",							icon: "mdi-file-check",						route: "/resumen-cuenta", hideForRole: [2,9] },
					// { text: "Comprobantes",				icon: "mdi-file-download",				route: "/comprobantes", hideForRole: [2,9] },
					{ text: "Estado de Cuenta", icon: "mdi-file-check", route: "/comprobantes", hideForRole: [2, 9] },
					{ text: "Aviso de pago", icon: "mdi-message-star", route: "/aviso-pago", hideForRole: [2, 9] },
				],
			},
			{
				text: "Configuración",
				icon: "mdi-cog",
				hideForRole: [9],
				children: [
					{ text: "Descuentos", icon: "mdi-ticket-percent", route: "/cfg-descuentos", hideForRole: [2, 9] },
					{ text: "Utilidades", icon: "mdi-sack-percent", route: "/cfg-utilidades", hideForRole: [2, 9] },
					{ text: "Mi cuenta", icon: "mdi-account", route: "/cfg-cuenta", hideForRole: [9] },
				],
			},
			// { text: "Contacto",								icon: "mdi-email",								route: "/contacto", hideForRole: [1,2,9]},
		],

	},

	mutations: {
		// SHIPMENT_AMOUNT
		setShipmentAmount(state, newValue) {
			state.shipmentAmount = newValue;			
		},

		// FREE_SHIPPING_FROM
		setFreeShippingFrom(state, newValue) {
			state.freeDeliveryFrom = newValue;
		},

		// ORDER_MINIMUM_AMOUNT
		setOrderMinimumAmount(state, newValue) {
			state.orderMinimumAmount = newValue;
		}


	},
	
		
};