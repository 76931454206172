import Vue				from 'vue';
import VueRouter	from 'vue-router';
import store			from '@/store/index.js';

Vue.use(VueRouter)
const folder = store.state.business.folder;

const routes = [
	{ path: '/',								name: 'home',						component: () => import('@/views/Home.vue'),								meta: {requireAuth: false} },
	{ path: '/login',						name: 'login',					component: () => import('@/views/Login.vue'),								meta: {requireAuth: false} },
	{ path: '/recovery',				name: 'recovery',				component: () => import('@/views/Recovery.vue'),						meta: {requireAuth: false} },
	{ path: '/register',				name: 'register',				component: () => import('@/views/Register.vue'),						meta: {requireAuth: false} },
	// { path: '/contacto',				name: 'contacto', 			component: () => import('@/views/Contacto.vue'), 						meta: {requireAuth: false} }, // not used yet
	{ path: '/flyers',					name: 'flyers', 				component: () => import('@/views/Flyers.vue'),							meta: {requireAuth: true} }, 
	{ path: '/novedades',				name: 'novedades', 			component: () => import('@/views/Novedades.vue'),						meta: {requireAuth: false} },
	// { path: '/aplicaciones',		name: 'aplicaciones',		component: () => import('@/views/Aplicaciones.vue'),				meta: {requireAuth: true} },
	{ path: '/catalogos',				name: 'catalogos',			component: () => import('@/views/Catalogos.vue'),						meta: {requireAuth: true} },
	{ path: '/comprobantes',		name: 'comprobantes',		component: () => import('@/views/Comprobantes.vue'),				meta: {requireAuth: true} },
	{ path: '/aviso-pago',			name: 'avisoPago', 			component: () => import('@/views/AvisoPago.vue'),						meta: {requireAuth: true} },	
	{ path: '/cfg-cuenta',			name: 'cfgCuenta', 			component: () => import('@/views/CfgCuenta.vue'),						meta: {requireAuth: true} },
	{ path: '/cfg-descuentos',	name: 'cfgDescuentos',	component: () => import('@/views/CfgDescuentos.vue'),				meta: {requireAuth: true} },
	{ path: '/cfg-utilidades',	name: 'cfgUtilidades',	component: () => import('@/views/CfgUtilidades.vue'),				meta: {requireAuth: true} },
	{ path: '/descargas',				name: 'descargas',			component: () => import('@/views/Descargas.vue'),						meta: {requireAuth: true} },
	{ path: '/payments',				name: 'payments',				component: () => import('@/views/Payments.vue'),						meta: {requireAuth: true} },
	{ path: '/carrito',					name: 'carrito',				component: () => import('@/views/OrderInProgress.vue'),			meta: {requireAuth: true} },
	{ path: '/pedidos',					name: 'pedidos',				component: () => import('@/views/OrdersHistory.vue'),				meta: {requireAuth: true} },
	{ path: '/productos',				name: 'productos',			component: () => import('@/views/'+folder+'Productos.vue'),	meta: {requireAuth: !store.state.business.isGuestEnabled } },	
	// { path: '/productosff',			name: 'productosff',		component: () => import('@/views/Productos_fixedFinder.vue'),	meta: {requireAuth: !store.state.business.isGuestEnabled } },
	{ path: '/resumen-cuenta',	name: 'resumenCuenta',	component: () => import('@/views/ResumenCuenta.vue'),				meta: {requireAuth: true} },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
	const rutaProtegida = to.matched.some(record => record.meta.requireAuth)
	if (rutaProtegida && !store.getters.isAuthenticated) {
		next({name: 'login'});
	} else {
		next();
	}
})

export default router;
