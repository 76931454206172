import Vue from "vue";
import Vuex, { Store } from "vuex";
import idbs from "@/api/indexedDBService";
import router from "@/router/index.js";
import axios from "axios";
import dayjs from "dayjs";
import { uuid } from "vue-uuid";
import { firstBy } from "thenby";

import business from "./business";
import appVersion from "./version";
// import utils from '@/mixins/mixins';

Vue.use(Vuex);

export default new Vuex.Store({
	modules: {
		business,
		appVersion,
	},

	// INFO: STATE ##################################################################
	state: {
		uname: "",
		token: JSON.parse(localStorage.getItem("token")) || undefined,
		// token: ls.checkStorage("token") || undefined,
		hashcode: localStorage.getItem("hashcode") || null,
		status: "",
		loading: false,
		deletingMargin: false,
		showSurvey: false,
		surveyData: [],
		geoLocation: {},
		updatingTables: [],
		account: {},
		viewConfig: {
			prods: { rowsPerPage: 12, showTable: false, mobileAutoSearch: false },
			appls: { rowsPerPage: 12, showTable: false },
		},
		appConfig: {
			sellMode: true,
			roleId: 0,
			isAdmin: false,
			isCustomer: false,
			isGuest: true,
			branchId: 0,
			isReseller: false, // Alias for "Reseller + Adminstrator"
			isOperator: false, // Alias for "Reseller + Operator (no admin)"
			isCorporate: false,
			isFinal: false,			
			imagesUrl: "/backend/doc/prods/",
		},
		customers: [],
		brands: [],
		items: [],
		subitems: [],
		discounts: [],
		extraDiscount: {},
		margins: [],
		products: [],
		tags: [],
		technicals: [],
		aplBrand: [],
		aplFuel: [],
		aplType: [],
		aplCategory: [],
		aplApplication: [],
		documents: [],
		equivalents: [],
		alternatives: [],
		catalogs: [],
		slides: [],
		paymentsNotices: [],
		shippingAddresses: [],
		syncs: {},
		updateInterval: 3, // in hours
		orderCurrent: {},
		orderHistory: [],
		pedidoItem: {},
		snackBar: { text: "", color: "info", show: false }, // requiere => ...mapState(['snackBar']) y ...mapActions(['showSnack']) en app.vue
		showProductData: [],
		sendingCart: false,

		objCheckOut: {
			stepId: 1,
			confirmed: false,
			delivery_method: "ENVIAR",
			payment_method: "MP",
			subtotal: 0,
			shipment_amount: 0,
			amount_total: 0,
			email: "",
			isNewCustomer: false,
			isNewUser: false,
			userExist: false,
			order: {},
			customer: {},
			shipping_address: {},
			taxes: [
				{ name: "Percepción provincial", value: 0, amount: 0 },
				{ name: "RG 212", value: 0, amount: 0 },
				// {name: "Otros", value: 0},
			],
		},
		gettingOrders: false,
	},

	// INFO: MUTATIONS ##################################################################
	mutations: {
		setState(state, { field, data }) {
			Vue.set(state, field, data);
		},

		// CRUD items del orderCurrent
		addCartItem(state, newItem) {
			let index = -1;
			if (state.orderCurrent.details) {
				index = state.orderCurrent.details.findIndex((cur) => cur.article && cur.article.id == newItem.article.id);
			}
			if (index >= 0) {
				state.orderCurrent.details[index].quantity = newItem.quantity;
				state.orderCurrent.details[index].subtotal = newItem.subtotal;
				state.orderCurrent.details[index].deleted = null;
				state.orderCurrent.details[index].modified = dayjs().format("YYYY-MM-DD HH:mm:ss");
			} else {
				newItem.uuid = uuid.v4();
				state.orderCurrent.details.push(newItem);
			}
			state.orderCurrent.uploaded = false;
		},

		updateCartItem(state, updItem) {
			if (!updItem.uuid) {
				updItem.uuid = uuid.v4();
			}
			updItem.modified = dayjs().format("YYYY-MM-DD HH:mm:ss");
			const index = state.orderCurrent.details.findIndex((cur) => cur.article.id == updItem.article.id);
			Object.assign(state.orderCurrent.details[index], updItem);
			state.orderCurrent.modified = dayjs().format("YYYY-MM-DD HH:mm:ss");
			state.orderCurrent.uploaded = false;
		},

		deleteCartItem(state, item) {
			const index = state.orderCurrent.details.findIndex((cur) => cur.article.id == item.article.id);
			if (index >= 0) {
				state.orderCurrent.details[index].deleted = dayjs().format("YYYY-MM-DD HH:mm:ss");
				state.orderCurrent.uploaded = false;
			}
		},

		updateCartTotal(state) {
			state.orderCurrent.totalCost = 0;
			state.orderCurrent.totalSell = 0;
			if (state.orderCurrent.details) {
				const objTotal = state.orderCurrent.details
					.filter((itm) => !itm.deleted && itm.article.stock > 0)
					.reduce((prev, cur) => prev + cur.quantity * cur.article.price * (state.appConfig.isOperator || state.appConfig.isFinal ? 1 + cur.article.tax / 100 : 1), 0);

				// this.formatNumber(item.quantity * item.article.price * ((this.appConfig.isOperator || this.appConfig.isFinal) ? (1+item.article.tax/100) : 1))

				const objTotalCost = state.orderCurrent.details
					.filter((itm) => !itm.deleted && itm.article.stock > 0)
					.reduce((prev, cur) => prev + cur.article.priceCost * cur.quantity, 0);

				const objTotalSell = state.orderCurrent.details
					.filter((itm) => !itm.deleted && itm.article.stock > 0)
					.reduce((prev, cur) => prev + cur.article.priceSell * cur.quantity, 0);

				const subtotalTaxes = +parseFloat(state.objCheckOut.taxes.reduce((prev, cur) => prev + state.orderCurrent.total * (1 + cur.value / 100))).toFixed(2) || 0;

				state.orderCurrent.total = +parseFloat(objTotal.toFixed(2));
				state.orderCurrent.totalCost = +parseFloat(objTotalCost.toFixed(2));
				state.orderCurrent.totalSell = +parseFloat(objTotalSell.toFixed(2));

				state.objCheckOut.shipment_amount =
					state.objCheckOut.delivery_method == "RETIRAR" ? 0 : +state.orderCurrent.total >= +state.business.freeDeliveryFrom ? 0 : +state.business.shipmentAmount;

				// state.objCheckOut.shipment_amount = (state.objCheckOut.delivery_method == "RETIRAR" || (state.orderCurrent.total >= state.business.freeDeliveryFrom))
				// 											? 0
				// 											: state.business.shipmentAmount;

				// console.log('state.objCheckOut.shipment_amount', state.objCheckOut.shipment_amount)

				state.objCheckOut.amount_total = state.orderCurrent.total + subtotalTaxes + state.objCheckOut.shipment_amount;
			}
		},

		// NOTE: CRUD orderCurrent
		cartCreate(state) {
			const dt = dayjs().format("YYYY-MM-DD HH:mm:ss");
			let newOrder = {
				id: 0,
				uuid: null,
				customerId: null,
				details: [],
				closed: 0,
				comment: "",
				totalCost: 0,
				totalSell: 0,
				status: null,
				sellerId: null,
				sendTo: state.business.default_sendTo || 1,
				date: null,
				dateSent: null,
				dateChecked: null,
				transportId: 1,
				uploaded: false,
				payStatus: null,
				created: null,
				modified: null,
				deleted: null,
			};
			newOrder.uuid = state.account.id ? state.account.id + "-" + uuid.v4() : uuid.v4();
			newOrder.customerId = state.account.id;
			newOrder.sellerId = state.account.sellers ? state.account.sellers[0].id : null;
			newOrder.transportId = state.account.transport ? state.account.transport.id : 1;
			newOrder.date = dt;
			newOrder.created = dt;
			newOrder.details = [];
			Object.assign(state.orderCurrent, newOrder);
		},

		cartClean(state) {
			const dt = dayjs().format("YYYY-MM-DD HH:mm:ss");
			state.orderCurrent.details.forEach((row) => (row.deleted = dt));
			state.orderCurrent.comment = "";
			state.orderCurrent.totalCost = 0;
			state.orderCurrent.totalSell = 0;
			state.orderCurrent.modified = dt;
			state.orderCurrent.uploaded = false;
			state.orderCurrent.payStatus = null;
		},

		cartUpdate(state, newData) {
			if (!newData.details) {
				newData.details = [];
			}
			newData.details.forEach((item) => {
				if (!item.uuid) {
					item.uuid = uuid.v4();
				}
			});
			newData.uploaded = false;
			Object.assign(state.orderCurrent, newData);
		},

		cartRepeat(state, itemsToRepeat) {
			const dt = dayjs().format("YYYY-MM-DD HH:mm:ss");
			if (!state.orderCurrent.details) {
				state.orderCurrent.details = [];
			}
			itemsToRepeat.map((row) => {
				let indexExiste = -1;
				if (state.orderCurrent.details) {
					indexExiste = state.orderCurrent.details.findIndex((cual) => cual.articleId == row.articleId);
				}
				if (indexExiste < 0) {
					row.uuid = uuid.v4();
					row.id = null;
					row.created = dt;
					row.modified = dt;
					row.deleted = null;
					row.dateSent = null;
					row.dateChecked = null;
					row.orderId = state.orderCurrent.id;
					state.orderCurrent.details.push(row);
				} else {
					if (state.orderCurrent.details[indexExiste].deleted !== null) {
						state.orderCurrent.details[indexExiste].deleted = null;
						state.orderCurrent.details[indexExiste].created = dt;
						state.orderCurrent.details[indexExiste].modified = dt;
						state.orderCurrent.details[indexExiste].dateSent = null;
						state.orderCurrent.details[indexExiste].dateChecked = null;
						state.orderCurrent.details[indexExiste].orderId = state.orderCurrent.id;
					}
				}
			});
			state.orderCurrent.uploaded = false;
		},

		cartConfirm(state, info) {
			state.orderCurrent.sendTo = info.sendTo;
			state.orderCurrent.comment = info.comment;
			state.orderCurrent.closed = 1;
			state.orderCurrent.dateSent = dayjs().format("YYYY-MM-DD HH:mm:ss");
			state.orderCurrent.uploaded = false;
			state.orderCurrent.payStatus = info.payStatus;
			if (!state.orderCurrent.transportId) {
				// WARNING: Revisar que funcione que le ponga el transporte por defecto de la cuenta, si no viene cargado de antes.
				state.orderCurrent.transportId = state.account?.transport?.id || 1;
			}
		},

		cartUnconfirm(state) {
			state.orderCurrent.closed = 0;
			state.orderCurrent.uploaded = false;
			state.orderCurrent.dateSent = null;
			state.orderCurrent.payStatus = null;
		},

		setSendResults(state, results) {
			state.orderCurrent.uuid = results.uuid;
			state.orderCurrent.id = results.id;
			state.orderCurrent.details = results.details;
			state.orderCurrent.status = results.status;
			state.orderCurrent.uploaded = results.uploaded;
			state.orderCurrent.payStatus = results.payStatus;
		},

		// NOTE: CRUD margins
		marginItemAdd(state, newItem) {
			state.margins.push(newItem);
		},

		marginItemUpdate(state, updItem) {
			const index = state.margins.findIndex((cual) => cual.id == updItem.id);
			state.margins[index] = updItem;
		},

		marginItemDelete(state, delItem) {
			delItem.map((row) => {
				const index = state.margins.findIndex((cual) => cual.id == row.id);
				state.margins[index].deleted = row.deleted;
			});
		},

		extraDiscountUpdate(state, newValues) {
			state.extraDiscount.value = +newValues.discount;
			state.extraDiscount.add_sales_price = newValues.add_sales_price;
			if (state.extraDiscount.created == null) {
				state.extraDiscount.created = dayjs().format("YYYY-MM-DD HH:mm:ss");
			}
			state.extraDiscount.modified = dayjs().format("YYYY-MM-DD HH:mm:ss");
		},

		addPaymentNotice(state, newPaySent) {
			state.paymentsNotices.push(newPaySent);
		},
	},

	// INFO: ACTIONS ##################################################################
	actions: {
		async changeAccount({ state, commit, dispatch }) {
			commit("setState", {
				field: "objCheckOut",
				data: {
					stepId: 1,
					confirmed: false,
					delivery_method: "ENVIAR",
					payment_method: "MP",
					email: "",
					isNewCustomer: false,
					isNewUser: false,
					userExist: false,
					customer: {},
					shipping_address: {},
					taxes: [
						{ name: "Percepción provincial", value: 0, amount: 0 },
						{ name: "RG 212", value: 0, amount: 0 },
					],
				},
			});
			commit("setState", { field: "token", data: null });
			commit("setState", { field: "uname", data: null });
			await idbs.clearStorage("osapp");
			location.reload();
		},

		async logOut({ state, commit, dispatch }) {
			const info = { code: "LOGOUT", info: { userName: state.account.user_name } };
			dispatch("registerEvent", info);
			localStorage.removeItem("token");
			localStorage.removeItem("token");
			localStorage.removeItem("uname");
			localStorage.removeItem("orderCurrent");
			commit("setState", { field: "token", data: null });
			commit("setState", { field: "uname", data: null });
			commit("setState", { field: "orderCurrent", data: null });
			commit("setState", { field: "orderHistory", data: null });
			await idbs.clearStorage("osapp");
			router
				.push("/")
				.then((res) => {
					// nothing
				})
				.catch((err) => {
					console.log("** ERROR ** Log Out", err);
				})
				.finally(() => {
					console.log("Session closed.");
					location.reload();
				});
		},

		async logIn({ state, commit, dispatch }, userpass) {
			try {
				userpass.hashcode = await dispatch("generateRandomHash", userpass.username);
				const res = await axios.post("/wslg.php", JSON.stringify(userpass));
				if (!res.data.message) {
					const resData = res.data;
					commit("setState", { field: "token", data: resData.token });
					commit("setState", { field: "uname", data: userpass.username });
					localStorage.setItem("uname", state.uname);
					await dispatch("getCustomers", { user_name: userpass.username, user_id: resData.user_id });

					const shippings = await dispatch("readShippingAddress");
					commit("setState", { field: "shippingAddresses", data: shippings });

					await dispatch("setConfigOptions", resData);
					await dispatch("getOrdersHistory");
					await dispatch("getOrdersCurrent");

					console.log(`Wellcome ${userpass.username}!`);
					return true;
				} else {
					if (this.getters.isDeveloping) {
						console.log("login post:", res.data);
					}
				}
			} catch (err) {
				if (userpass.username !== "invitado") {
					commit("setState", { field: "snackBar", data: { text: "Usuario o contraseña incorrecto.", color: "error", show: true } });
				}
				console.log("** ERROR ** Lgn", err);
			}
			return false;
		},

		async remoteLogin({ state, commit, dispatch }, customercode) {
			try {
				const res = await axios.post("/remotewslg.php", JSON.stringify({ hashcode: state.hashcode, customercode }));
				if (!res.data.message) {
					const resData = res.data;
					commit("setState", { field: "token", data: resData.token });
					commit("setState", { field: "uname", data: resData.username });
					localStorage.setItem("uname", state.uname);
					await dispatch("getCustomers", { user_name: resData.username, user_id: resData.user_id });

					const shippings = await dispatch("readShippingAddress");
					commit("setState", { field: "shippingAddresses", data: shippings });

					await dispatch("setConfigOptions", resData);
					console.log(`Wellcome ${resData.username}!`);
					return true;
				} else {
					if (this.getters.isDeveloping) {
						console.log("** ERROR ** RmtLg", res.data);
					}
				}
			} catch (err) {
				commit("setState", { field: "snackBar", data: { text: "Usuario no encontrado", color: "error", show: true } });
				router.push({ name: "register" });
			}
			return false;
		},

		async setConfigOptions({ state, commit }, resData) {
			try {
				let roleId = 4; // -1; // Rol por defecto: 4 - consumidor final.
				let cfg = Object.assign({}, state.appConfig);
				cfg.isAdmin = +resData.is_cust_admin === 1;
				cfg.isGuest = +resData.is_guest === 1;
				cfg.isCustomer = +resData.is_customer === 1 && +resData.is_guest === 0;
				cfg.sellMode = true;

				cfg.isReseller = state.account.profile_code == "R" && cfg.isAdmin;
				cfg.isOperator = state.account.profile_code == "R" && !cfg.isAdmin;
				cfg.isCorporate = state.account.profile_code == "C";
				cfg.isFinal = state.account.profile_code == "F" || cfg.isGuest;

				if (cfg.isReseller) {
					roleId = 1;
				}
				if (cfg.isOperator) {
					roleId = 2;
				}
				if (cfg.isCorporate) {
					roleId = 3;
				}
				if (cfg.isFinal) {
					roleId = 4;
				}
				if (cfg.isGuest && !cfg.isAdmin) {
					roleId = 9;
				}
				cfg.roleId = roleId;
				if (cfg.roleId !== 9) {
					localStorage.setItem("token", JSON.stringify(resData.token));
				} else {
					localStorage.setItem("token", JSON.stringify(null));
				}
				// 
				if (state.business.defaultUrlImages){
					cfg.imagesUrl = state.business.defaultUrlImages;
				}else{
					cfg.imagesUrl = "/backend/doc/prods/";
				}				
				commit("setState", { field: "appConfig", data: cfg });
			} catch (err) {
				console.log("** ERROR ** Config Options");
			}
		},

		async checkAliveAndReconfig({ state, commit, dispatch }) {
			if (!state.token) return;
			try {
				const res = await axios.post("/user/user.php", JSON.stringify({ token: state.token, target: "alive" }));
				let resData = res.data;
				if (!res.data.message) {
					await dispatch("getCustomers", res.data);
					await dispatch("setConfigOptions", res.data);
					const shippings = await dispatch("readShippingAddress");
					commit("setState", { field: "shippingAddresses", data: shippings });
					await dispatch("getOrdersHistory");
					await dispatch("getOrdersCurrent");
				} else {
					console.error("User account unavailable. Executing logout...", res.data);
					dispatch("logOut", true);
				}
			} catch (err) {
				console.log("** ERROR ** Check Alive", err);
			}
		},

		async getCustomers({ state, commit }, userData) {
			if (state.token) {
				let myAccount = [];
				try {
					const resCustomer = await axios.post("/cliente/read.php", JSON.stringify({ token: state.token }));
					if (resCustomer.data) {
						myAccount = resCustomer.data[0];
						myAccount.user_name = userData.user_name;
						myAccount.user_id = userData.user_id;
						commit("setState", { field: "account", data: myAccount });
					}
				} catch (err) {
					console.log("** ERROR ** Get Customer", err);
				}
			}
		},

		changePassword({ commit }, passChange) {
			console.log(JSON.stringify(passChange));

			axios
				.post("/user/user.php", JSON.stringify(passChange))
				.then((res) => {
					if (res.data.message.toLowerCase().includes("exito")) {
						commit("setState", { field: "snackBar", data: { text: "Tu contraseña fue modificada con éxito.", color: "green", show: true } });
					} else {
						commit("setState", { field: "snackBar", data: { text: "No pudimos cambiar tu contraseña. Verificá que estén bien escritas.", color: "error", show: true } });
					}
				})
				.catch((err) => {
					console.log("** ERROR ** Change Password: ", err);
					commit("setState", { field: "snackBar", data: { text: err.data.error, color: "error", show: true } });
				});
		},

		async resetPassword({ commit }, recoveryData) {
			let res = await axios.post("/user/user.php", JSON.stringify(recoveryData));
			if (res.data.message.toLowerCase().includes("exito")) {
				commit("setState", {
					field: "snackBar",
					data: { text: "Te enviamos la nueva contraseña a tu email. Revisá tu casilla de correo y también en spam o correo no deseado.", color: "green", show: true },
				});
			} else {
				commit("setState", {
					field: "snackBar",
					data: { text: "No pudimos generar una nueva contraseña. Intentalo más tarde o escribinos a nuestro WhatsApp.", color: "error", show: true },
				});
			}
		},

		async readStorage({ state, commit, dispatch }) {
			await Promise.all(
				idbs.dataFields.map(async (field) => {
					try {
						let data = await idbs.readStorage(field);
						if (data === undefined) data = JSON.parse(localStorage.getItem(field));
						if (!data || data === null || data.length <= 0 || data.stack) {
							data = [];
							if (field === "viewConfig") {
								Object.assign(data, state.viewConfig);
								dispatch("saveOne", "viewConfig");
							}
						}
						commit("setState", { field, data });
						// dispatch("saveOne", "viewConfig");
					} catch (err) {
						console.log("** ERROR ** Read Storage", err);
						commit("setState", { field, data: [] });
					}
				})
			);
			if (state.token) {
				await dispatch("checkAliveAndReconfig");
			}
			console.log("Storage loaded.");
			dispatch("getSurveys");
		},

		setGeoLocation({ commit }, geoloc) {
			commit("setState", { field: "geoLocation", data: geoloc });
		},

		async generateRandomHash(username) {
			let hashcode = localStorage.getItem("hashcode");
			if (!hashcode) {
				const randomValue = username !== "invitado" ? new Uint8Array(16) : "guest";
				crypto.getRandomValues(randomValue);
				const encoder = new TextEncoder();
				const randomString = encoder.encode(String.fromCharCode.apply(null, randomValue));
				const hashBuffer = await crypto.subtle.digest("SHA-256", randomString);
				const hashArray = Array.from(new Uint8Array(hashBuffer));
				hashcode = hashArray.map((byte) => byte.toString(16).padStart(2, "0")).join("");
				localStorage.setItem("hashcode", hashcode);
			}
			return hashcode;
		},

		async addCartItem({ commit, dispatch }, cartItem) {
			commit("addCartItem", cartItem);
			commit("updateCartTotal");
			await dispatch("cartToServer");
			commit("setState", { field: "snackBar", data: { text: "Producto agregado al pedido.", color: "success", show: true } });
		},

		async updateCartItem({ state, commit, dispatch }, cartItem) {
			commit("updateCartItem", cartItem);
			commit("updateCartTotal");
			await dispatch("cartToServer");
			if (!state.appConfig.isFinal) {
				commit("setState", { field: "snackBar", data: { text: "Pedido actualizado.", color: "success", show: true } });
			}
		},

		async deleteCartItem({ commit, dispatch }, cartItem) {
			commit("deleteCartItem", cartItem);
			commit("updateCartTotal");
			await dispatch("cartToServer");
			commit("setState", { field: "snackBar", data: { text: "Producto eliminado del pedido.", color: "success", show: true } });
		},

		async cartClean({ commit, dispatch }, showAlert = false) {
			commit("cartClean");
			await dispatch("cartToServer");
			if (showAlert) {
				commit("setState", { field: "snackBar", data: { text: "Se eliminaron todos los ítems del pedido.", color: "success", show: true } });
			}
		},

		async cartUpdate({ commit, dispatch }, newData) {
			commit("cartUpdate", newData);
			await dispatch("cartToServer");
		},

		async cartRepeat({ commit, dispatch }, orderToRepeat) {
			commit("cartRepeat", orderToRepeat);
			await dispatch("cartToServer");
			commit("setState", { field: "snackBar", data: { text: "Productos agregados al pedido actual.", color: "success", show: true } });
		},

		async cartConfirm({ commit, dispatch }, info) {
			commit("cartConfirm", info);
			await dispatch("cartToServer"); // Grabo el pedido confirmado
			commit("cartCreate"); // creo un nuevo pedido (OrderCurrent)
			await dispatch("cartToServer"); // >>> Ggrabo el nuevo pedido - vacío
			await dispatch("getOrdersHistory"); // obtengo los históricos, donde debería estar el último confirmado lineas arriba.
		},

		async cartToServer({ state, getters, commit, dispatch }) {
			let result = false;
			if (!state.sendingCart) {
				if (state.token && getters.isAuthenticated) {
					if (!state.orderCurrent.uploaded) {
						const today = dayjs().format("YYYY-MM-DD HH:ss:mm");
						commit("setState", { field: "sendingCart", data: true });
						const orderToSend = {
							amountCollected: 0,
							closed: state.orderCurrent.closed,
							comment: state.orderCurrent.comment || "",
							customerBranchId: 0,
							customerId: state.account.id,
							date: state.orderCurrent.modified || today,
							created: state.orderCurrent.created || today,
							modified: state.orderCurrent.modified || today,
							details: [],
							location: (state.geoLocation.latitude || 0) + "," + (state.geoLocation.longitude || 0),
							receiptNumber: 0,
							sellerId: state.orderCurrent.sellerId,
							sendTo: state.orderCurrent.sendTo,
							payStatus: state.orderCurrent.payStatus,
							transportId: state.orderCurrent.transportId || 1,
							visitId: 0,
							uuid: state.orderCurrent.uuid || uuid.v4(),
							dateSent: null,
							dateChecked: null,
							shipmentAmount: state.objCheckOut.shipment_amount,
						};

						state.orderCurrent.details.map((item) => {
							const pedidoItem = {
								orderId: state.orderCurrent.id,
								articleId: item.article ? item.article.id : item.articleId,
								quantity: item.quantity,
								// price: item.price, // WARNING debe ser el precio neto del producto (con descuento aplicado)
								price: parseFloat((item.price * (1 - item.discount / 100)).toFixed(2)), // WARNING debe ser el precio neto del producto (con descuento aplicado)
								priceList: item.price,
								priceType: "discount_general",
								discount: parseFloat((+item.discount).toFixed(2)),
								uuid: item.uuid,
								dateSent: null,
								dateChecked: null,
								created: item.created,
								deleted: item.deleted, // !item.article ? today : item.deleted,
								modified: item.modified,
							};

							// console.log('precio:', pedidoItem.price);

							orderToSend.details.push(pedidoItem);
						});
						const pedidoToSend = {
							token: state.token,
							customerId: state.account.id,
							orders: [orderToSend],
						};
						try {
							// console.log("put de pedido:", JSON.stringify(orderToSend.details));
							let results = await axios.post("/pedido/put.php", JSON.stringify(pedidoToSend));
							let returnedData = results.data[0].orders[0];
							commit("setSendResults", { ...returnedData, uploaded: true });
							if (+returnedData.closed === 1) {
								commit("setState", { field: "snackBar", data: { text: "Recibimos tu pedido correctamente!", color: "success", show: true } });
								result = true;
								await dispatch("getOrdersCurrent");
							} else {
								await dispatch("recalculateOrder", returnedData);
							}
						} catch (err) {
							if (+orderToSend.closed === 1) {
								commit("cartUnconfirm");
								commit("setState", {
									field: "snackBar",
									data: { text: "Hubo un problema al enviar tu pedido. Reintentá en unos minutos...", color: "error", show: true },
								});
							}
						} finally {
							commit("setState", { field: "sendingCart", data: false });
						}
					}
				} else {
					if (state.business.isGuestEnabled) {
						localStorage.setItem("orderCurrent", JSON.stringify(state.orderCurrent));
					}
				}
			}
			return result;
		},

		async recalculateOrder({ state, getters, commit }, recalcOrder) {
			recalcOrder.totalCost = 0;
			recalcOrder.totalSell = 0;
			if (!recalcOrder.details || recalcOrder.details == null || recalcOrder.details == undefined) {
				recalcOrder.details = [];
			}
			if (recalcOrder.details.length) {
				let recalculatedItems =
					recalcOrder.details.map((item) => {
						if (item.article) {
							item.article.stockIconColor = getters.getStockIconAndColor(item.article);
							item.price = +item.article.price; // * (1 - (item.article.discount / 100));
							item.priceList = +item.article.price;
							item.article.price = +item.article.price;
							item.article.sale_price = +item.article.sale_price;
							item.article.discount = +item.article.discount;
							item.article.margin = +item.article.margin;
							item.article.tax = +item.article.tax || 0;
							item.article.extra_discount = +item.article.extra_discount;
							let costExtra = item.article.price * (1 - item.article.discount / 100) * (1 - item.article.extra_discount / 100);
							let costNormal = item.article.price * (1 - item.article.discount / 100);
							let sell = (+item.article.add_sales_price == 1 ? costExtra : costNormal) * (1 + item.article.tax / 100) * (1 + item.article.margin / 100);
							item.article.priceCost = +costExtra.toFixed(2);
							item.article.priceSell = +sell.toFixed(2);
							item.subtotalCost = +parseFloat(item.article.priceCost * item.quantity).toFixed(2);
							item.subtotalSell = +parseFloat(item.article.priceSell * item.quantity).toFixed(2);
						}
						return item;
					}) || [];
				recalcOrder.details = recalculatedItems;

				recalcOrder.totalCost =
					+parseFloat(
						recalcOrder.details
							.filter((itm) => !itm.deleted && itm.article && +itm.article.stock > 0)
							.reduce((prev, cur) => prev + cur.article.priceCost * cur.quantity, 0)
							.toFixed(2)
					) || 0;

				recalcOrder.totalSell =
					+parseFloat(
						recalcOrder.details
							.filter((itm) => !itm.deleted && itm.article && +itm.article.stock > 0)
							.reduce((prev, cur) => prev + cur.article.priceSell * cur.quantity, 0)
							.toFixed(2)
					) || 0;
			}
			commit("setState", { field: "orderCurrent", data: recalcOrder });
		},

		async getOrdersCurrent({ state, getters, commit, dispatch }) {
			if (state.token && getters.isAuthenticated) {
				if (!state.gettingOrders) {
					commit("setState", { field: "gettingOrders", data: true });
					this.gettingOrders = true;
					let remoteOrder = undefined;
					try {
						let remoteOrderHeader = await axios.post("/pedido/read.php", JSON.stringify({ token: state.token, target: "current", customerId: state.account.id }));
						if (remoteOrderHeader.data.id !== undefined) {
							// if (this.getters.isDeveloping) { console.log("Getting Orders Current", remoteOrderHeader.data)}
							const orderUUID = remoteOrderHeader.data.uuid;
							const resOrderWithDetails = await axios.post(
								"/pedido/read.php",
								JSON.stringify({ token: state.token, target: "get", customerId: state.account.id, uuid: orderUUID })
							);
							remoteOrder = resOrderWithDetails.data;
							if (!remoteOrder.details || remoteOrder.details == null || remoteOrder.details == undefined) {
								remoteOrder.details = [];
							}

							if (remoteOrder.details.length) {
								remoteOrder.details = remoteOrder.details.map((item) => {
									if (item.article == null || item.article.deleted || item.article.discontinued == 1) {
										item.deleted = dayjs().format("YYYY-MM-DD HH:ss:mm");
									} else {
										if (state.business.isDemo) {
											item.article.img_demo = "https://picsum.photos/600/400?random=" + 1 * Math.random();
										}
									}
									return item;
								});
							}
							commit("setState", { field: "orderCurrent", data: remoteOrder });
							await dispatch("recalculateOrder", remoteOrder);
							await dispatch("cartToServer");
						} else {
							commit("cartCreate");
						}
					} catch (err) {
						console.log("** ERROR ** Get Order Current", err, remoteOrder);
					} finally {
						commit("setState", { field: "gettingOrders", data: false });
					}
				}
			} else {
				console.log("** NO TOKEN ** Cart working on local mode");
				if (state.business.isGuestEnabled) {
					let myCart = localStorage.getItem("orderCurrent");
					if (myCart == null) {
						const dt = dayjs().format("YYYY-MM-DD HH:mm:ss");
						myCart = {
							id: 0,
							uuid: null,
							customerId: null,
							details: [],
							closed: 0,
							comment: "",
							total: 0,
							totalCost: 0,
							totalSell: 0,
							status: null,
							sellerId: null,
							sendTo: 2,
							transportId: 0,
							date: null,
							dateSent: null,
							dateChecked: null,
							created: null,
							modified: null,
							deleted: null,
							uploaded: false,
						};
						myCart.uuid = uuid.v4();
						myCart.date = dt;
						myCart.created = dt;
						myCart.modified = dt;
					} else {
						myCart = JSON.parse(myCart);
					}
					commit("setState", { field: "orderCurrent", data: myCart });
					localStorage.setItem("orderCurrent", JSON.stringify(state.orderCurrent));
				}
			}
		},

		async clearAndReplaceRemoteOrder({ state, getters, commit, dispatch }) {
			console.log("------ CARGAMOS LOS ITEMS DE LOCALSTORAGE");
			let myCart = localStorage.getItem("orderCurrent");
			if (myCart) {
				myCart = JSON.parse(myCart);
				console.log("myCART", myCart.details);

				let curCart = state.orderCurrent;
				console.log("curCART", curCart.details);

				curCart.details = myCart.details;

				console.log("curCART updated", curCart.details);

				await dispatch("recalculateOrder", curCart);
				await dispatch("cartToServer");
				// 	commit("setState", { field: "orderCurrent", data: curCart });
				localStorage.removeItem("orderCurrent");
				// }
			}
		},

		async getOrdersHistory({ state, commit }) {
			let ordersHistory = [];
			if (state.token) {
				try {
					const res = await axios.post("/pedido/read.php", JSON.stringify({ token: state.token, target: "list", limit: 15, customerId: state.account.id }));
					if (res.data) {
						// if (this.getters.isDeveloping) { console.log("Getting Orders History", res.data)}
						const orders = res.data.orders?.filter((ord) => +ord.closed === 1) || [];
						ordersHistory = await orders.map((ord) => {
							if (ord.details) {
								// return { ...ord, total: +parseFloat((ord.details || []).reduce((ttl, cur) => ttl + cur.price * cur.quantity, 0)).toFixed(2) || 0 };
								return { ...ord };
							} else {
								return { ...ord, total: 0 };
							}
						});
					}
				} catch (err) {
					console.log("** ERROR ** Get Order History", err);
				} finally {
					commit("setState", { field: "orderHistory", data: ordersHistory });
				}
			} else {
				console.log("** NO TOKEN ** Get Orders History");
			}
			return ordersHistory;
		},

		async getOrdersHistoryDetails({ state }, orderUuid) {
			if (state.token) {
				let getOHD = [];
				try {
					// console.log('getOrdersHistorDeta', JSON.stringify({token: state.token, target: "get", customerId: state.account.id, uuid: orderUuid}));
					const res = await axios.post("/pedido/read.php", JSON.stringify({ token: state.token, target: "get", customerId: state.account.id, uuid: orderUuid }));
					if (res.data) {
						getOHD = res.data;
						getOHD.details.map((row) => {
							row.subtotal = +row.quantity * +row.price;
						});
					}
				} catch (err) {
					console.log("** ERROR ** Get Order History Details", err);
				}
				return getOHD;
			} else {
				console.log("** NO TOKEN** Get Orders History Details");
			}
		},

		async saveOne({ state }, field) {
			try {
				await idbs.saveToStorage(field, state[field]);
			} catch (err) {
				console.log("** ERROR ** Save One", err);
			}
		},

		async getDocuments({ state }, dateInfo) {
			try {
				const resDocs = await axios.post(
					"/document/read.php",
					JSON.stringify({ token: state.token, customerId: state.account.id, dateFrom: dateInfo.from, dateTo: dateInfo.to })
				);
				return resDocs.data;
			} catch (err) {
				console.log("** ERROR ** Get Documents", err);
			}
			return [];
		},

		async getBrands({ state, commit }, mode = "tree") {
			let newData = [];
			try {
				const resBrands = await axios.post("/marca/read.php", 
					JSON.stringify({token: state.token, customerId: state.account.id, mode}));
				if (resBrands.data) {
					newData = resBrands.data.filter((b) => b.deleted == null);
					let ordSubitems, ordItems;
					newData.forEach((marca, idxMarca) => {
						marca.items.forEach((item) => {
							const allSubitems = {id: 0, item_id: item.id, code: "0", name: " TODOS", created: null, modified: null} 
							if (item.subitems) {
								item.subitems.push(allSubitems);							
							} else {
								item.subitems = [allSubitems];
							}
							ordSubitems = item.subitems.sort(firstBy("name"));
						});

						const allItems = {id: 0, brand_id: marca.id, code: "0", name: " TODOS", created: null, modified: null, subitems: ordSubitems}
						if (marca.items) {
							marca.items.push(allItems);
						} else {
							marca.items = [allItems];
						}
						ordItems = marca.items.sort(firstBy("name"));
						newData[idxMarca].items = ordItems;
					});
					newData.push({
						id: 0,
						code: "0",
						name: " TODAS (por defecto)",
						created: null,
						modified: null,
						deleted: null,
						items: [
							{
								id: 0,
								brand_id: 0,
								code: "0",
								name: " TODOS",
								created: null,
								modified: null,
								deleted: null,
								subitems: [
									{
										id: 0,
										item_id: 0,
										code: "0",
										name: " TODOS",
										created: null,
										modified: null,
										deleted: null,
									},
								],
							},
						],
					});
					newData = newData.sort(firstBy("name"));
				}
			} catch (err) {
				this.brands = [
					{
						id: 0,
						code: "0",
						name: " TODAS (por defecto)",
						created: null,
						modified: null,
						deleted: null,
						items: [
							{
								id: 0,
								brand_id: 0,
								code: "0",
								name: " TODOS",
								created: null,
								modified: null,
								deleted: null,
								subitems: [
									{
										id: 0,
										item_id: 0,
										code: "0",
										name: " TODOS",
										created: null,
										modified: null,
										deleted: null,
									},
								],
							},
						],
					},
				];
				console.log("** ERROR ** Get Brands", err);
			} finally {
				commit("setState", { field: "brands", data: newData });
			}
			return newData;
		},

		async getItems({ state, commit }, pattern = "") {
			let newData = [{ id: 0, code: "0", name: " TODOS", active: 1, created: null, modified: null, deleted: null }];
			try {
				const res = await axios.post("/item/read.php", JSON.stringify({ token: state.token, customerId: state.account.id, pattern }));
				if (res.data) {
					newData = newData.concat(res.data.filter((row) => !row.deleted).sort(firstBy("name")));
				}
			} catch (error) {
				console.log("** ERROR ** Get Items", err);
			} finally {
				commit("setState", { field: "items", data: newData });
			}
			return newData;
		},

		async getSubitems({ state, commit }, datos) {
			let newData = [{ id: 0, code: "0", name: " TODOS", active: 1, created: null, modified: null, deleted: null }];
			try {
				const res = await axios.post("/subitem/read.php", JSON.stringify({ token: state.token, customerId: state.account.id, ...datos }));
				if (res.data) {
					newData = res.data.filter((row) => !row.deleted);
				}
			} catch (err) {
				console.log("** ERROR ** Get Subitems", err);
			} finally {
				commit("setState", { field: "subitems", data: newData });
			}
			return newData;
		},

		async getProducts({ state }, parameters) {
			try {
				// if (this.getters.isDeveloping) {
				// console.log('parametros busca articulos:', JSON.stringify({...parameters, token: state.token, customerId: state.account.id}));
				// }
				const resProds = await axios.post("/articulo/read.php", JSON.stringify({ ...parameters, token: state.token, customerId: state.account.id }));
				if (resProds.data) {
					const recalculated = resProds.data.map((item) => {
						item.price = +item.price;
						item.discount = +item.discount;
						item.margin = +item.margin || (state.margins.length ? state.margins[0].margin : 40);
						item.tax = +item.tax || 0;
						item.extra_discount = +item.extra_discount || 0;
						const costExtra = +item.price * (1 - item.discount / 100) * (1 - item.extra_discount / 100);
						const costNormal = +item.price * (1 - item.discount / 100);
						const sell = (+item.add_sales_price == 1 ? costExtra : costNormal) * (1 + item.tax / 100) * (1 + item.margin / 100);
						item.priceCost = +costExtra.toFixed(2);
						item.priceSell = +sell.toFixed(2);
						item.stockIconColor = this.getters.getStockIconAndColor(item);
						if (state.business.isDemo || !item.image_name) {
							item.img_demo = "https://picsum.photos/600/400?random=" + 1 * Math.random();
						}
						return item;
					});
					return recalculated;
				}
			} catch (err) {
				console.log("** ERROR ** Get Products", err);
			}
			return [];
		},

		async getApplicationsProducts({ state }, datos) {
			let objAplics = { aplics: [], total: 0 };
			try {
				const result = await axios.post("/aplapplication/read.php", JSON.stringify(datos));
				if (result.data) {
					objAplics.total = result.data.record_count;
					const recalculated = result.data.data.map((item) => {
						item.article.price = +item.article.price;
						item.article.discount = +item.article.discount;
						item.article.margin = +item.article.margin || state.margins[0].margin;
						item.article.tax = +item.article.tax || 0;
						item.article.extra_discount = +item.article.extra_discount;
						let costExtra = item.article.price * (1 - item.article.discount / 100) * (1 - item.article.extra_discount / 100);
						let costNormal = item.article.price * (1 - item.article.discount / 100);
						let sell = (+item.article.add_sales_price == 1 ? costExtra : costNormal) * (1 + item.article.tax / 100) * (1 + item.article.margin / 100);
						item.article.priceCost = +costExtra.toFixed(2);
						item.article.priceSell = +sell.toFixed(2);
						item.article.stockIconColor = this.getters.getStockIconAndColor(item.article);
						if (state.business.isDemo) {
							item.article.img_demo = "https://picsum.photos/600/400?random=" + 1 * Math.random();
						}
						return item;
					});
					objAplics.aplics = recalculated;
				}
			} catch (err) {
				console.log("** ERROR ** Get Applications Products", err);
			}
			return objAplics;
		},

		async getDiscounts({ state, commit }) {
			if (state.token) {
				let objDiscounts = [];
				try {
					const resDiscounts = await axios.post("/discount/read.php", JSON.stringify({ token: state.token, customerId: state.account.id }));
					if (resDiscounts.data && resDiscounts.data.length > 0) {
						objDiscounts = resDiscounts.data;
					}
				} catch (err) {
					console.log("** ERROR ** Get Discounts", err);
				} finally {
					commit("setState", { field: "discounts", data: objDiscounts });
				}
			} else {
				console.log("** NO TOKEN ** Get Discounts");
			}
		},

		async getExtraDiscount({ state, commit }) {
			if (state.token) {
				let objExtraDiscount = [];
				try {
					const resExtraDiscount = await axios.post("/extradiscount/read.php", JSON.stringify({ token: state.token, customerId: state.account.id }));
					if (resExtraDiscount.data && resExtraDiscount.data.length > 0) {
						objExtraDiscount = resExtraDiscount.data[0];
					} else {
						objExtraDiscount = {
							add_sales_prices: 0,
							affectsSellPrice: false,
							value: 0,
							created: null,
							modified: null,
							deleted: null,
						};
					}
				} catch (err) {
					console.log("** ERROR ** Get Extradiscount", err);
				} finally {
					commit("setState", { field: "extraDiscount", data: objExtraDiscount });
				}
			} else {
				console.log("** NO TOKEN ** Get Extra Discount");
			}
		},

		async putExtraDiscount({ state, commit }, newValues) {
			try {
				commit("extraDiscountUpdate", newValues);
				const res = await axios.post(
					"/extradiscount/put.php",
					JSON.stringify({ token: state.token, customerId: state.account.id, value: state.extraDiscount.value, addSalesPrice: state.extraDiscount.add_sales_price })
				);
				if (res.data) {
					if (res.data.message.toLowerCase().includes("exito")) {
						commit("setState", { field: "snackBar", data: { text: "Descuento extra grabado correctamente!", color: "success", show: true } });
					} else {
						commit("setState", { field: "snackBar", data: { text: "No pude grabar el descuento extra", color: "error", show: true } });
					}
				}
			} catch (err) {
				console.log("** ERROR ** Put Extra Discounts", err);
			}
		},

		putAnswers({ state, commit }, answerData) {
			return new Promise((resolve, reject) => {
				answerData.token = state.token;
				answerData.uuid = uuid.v4();
				answerData.userId = state.account.user_id;
				answerData.customerId = state.account.id;
				answerData.deleted = null;
				axios
					.post("/survey/put.php", JSON.stringify(answerData))
					.then((res) => {
						if (answerData.canceled == 0) {
							commit("setState", {
								field: "snackBar",
								data: {
									text: "Gracias por tu respuesta!",
									color: "success",
									show: true,
								},
							});
						}
						resolve("OK");
					})
					.catch((error) => {
						console.log("** ERROR ** Put Surveys Answer", error);
						commit("setState", {
							field: "snackBar",
							data: {
								text: "No pudimos registrar la información!",
								color: "error",
								show: true,
							},
						});
						reject(error);
					});
			});
		},

		async getCatalogs({ state }) {
			try {
				const resCatalogs = await axios.post("/catalog/read.php", JSON.stringify({ token: state.token, customerId: state.account.id }));
				if (resCatalogs.data) {
					if (state.business.isDemo) {
						return resCatalogs.data.map((c) => {
							c.img_demo = "https://picsum.photos/600/400?random=" + 1 * Math.random();
							return c;
						});
					} else {
						return resCatalogs.data;
					}
				}
			} catch (err) {
				console.log("** ERROR ** Get Catalogs", err);
			}
			return [];
		},

		async increaseCatalogDownloads({ state }, catId) {
			try {
				const wasSuccess = await axios.post("/catalog/update.php", JSON.stringify({ token: state.token, customerId: state.account.id || 0, catalogId: catId }));
				if (!wasSuccess.data) {
					// console.log("NO PUDE ACTUALIZAR EL CONTADOR DE DESCARGAS DE CATÁLOGOS");
				} else {
					// console.log("SE ACTUALIZÓ")
				}
			} catch (err) {
				console.log("**** NO PUDE ACTUALIZAR EL CONTADOR DE DESCARGAS PORQUE:", err);
			}
		},

		async registerEvent({ state }, data) {
			try {
				const wasSuccess = await axios.post("/log/event.php", JSON.stringify({ token: state.token, ...data }));
				if (!wasSuccess.data) {
					// console.log("Event message: ", wasSuccess.data.message);
				}
			} catch (err) {
				console.log("**** NO PUDE REGISTRAR EL EVENTO:", err);
			}
		},

		async getSlides({ state }) {
			try {
				const resSlides = await axios.post("/slide/read.php", JSON.stringify({ token: state.token, customerId: state.account.id || 0 }));
				if (resSlides.data) {
					if (state.business.isDemo) {
						return resSlides.data.map((s) => {
							s.file_url = `https://picsum.photos/1920/500?image=${s.id * 10}`;
							return s;
						});
					} else {
						return resSlides.data;
					}
				}
			} catch (err) {
				console.log("** ERROR ** Get Slides", err);
			}
			return [];
		},

		async getPaymentsNoticesTypes({state}) {
			try {
				const resPaymentsTypes = await axios.post("/paymentNotice/read.php", JSON.stringify({token: state.token, customerId: state.account.id, target: "types"}));
				if (resPaymentsTypes.data) {
					return resPaymentsTypes.data;
				}
			}
			catch (err) {
				console.log("** ERROR ** Get PaymentsNoticesTypes", err);
			}
			return [];
		},

		async getPaymentsNotices({ state }) {
			try {
				const resPayments = await axios.post("/paymentNotice/read.php", JSON.stringify({token: state.token, customerId: state.account.id}));
				if (resPayments.data) {
					return resPayments.data;
				}
			} catch (err) {
				console.log("** ERROR ** Get PaymentsNotices", err);
			}
			return [];
		},

		async putPaymentsNotices({ state, commit }, payNoticeData) {
			payNoticeData.customerId = state.account.id;
			payNoticeData.sellerId = state.account.sellerId;
			payNoticeData.created = dayjs().format("YYYY-MM-DD HH:mm:ss");
			if (payNoticeData.image) {
				payNoticeData.image = payNoticeData.image.replace(/^data:(image|application)\/(png|jpg|jpeg|gif|webp|svg|bmp|pdf);base64,/, "");
			}
			let datos = {
				token: state.token,
				payments_notices: [payNoticeData],
			};
			console.log('datos payNotices:', JSON.stringify({ token: state.token, payments_notices: [payNoticeData] }));
			const res = await axios.post("/paymentNotice/put.php", JSON.stringify({ token: state.token, payments_notices: [payNoticeData] }));
			if (res.data.message.toLowerCase().includes("exito")) {
				datos.payments_notices[0].status = res.data.results[0].status;
				datos.payments_notices[0].uuid = res.data.results[0].uuid;
				commit("setState", { field: "snackBar", data: { text: "Aviso de pago enviado!", color: "success", show: true } });
				res.data.status = 200;
				return res.data;
			} else {
				commit("setState", {
					field: "snackBar",
					data: { text: "No se pudo enviar el aviso de pago. Reintente en unos minutos.", color: "error", show: true },
				});
				console.log("** ERROR ** Put Payments Notices", res.data.message);
				return res.data.message;
			}
		},

		async initializeMargins({ state, commit, dispatch }, defaultMargin) {
			commit("marginItemAdd", {
				id: 1,
				customer_id: state.account.id,
				brand_id: null,
				item_id: null,
				subitem_id: null,
				margin: defaultMargin,
				created: dayjs().format("YYYY-MM-DD HH:mm:ss"),
				modified: null,
				deleted: null,
			});
			if (state.appConfig.isReseller) {
				dispatch("putMargins");
			}
		},

		async marginItemAdd({ commit, dispatch }, itemToAdd) {
			commit("marginItemAdd", itemToAdd.data ? itemToAdd.data : itemToAdd);
			dispatch("putMargins", itemToAdd.relational);
			commit("setState", { field: "snackBar", data: { text: "Porcentaje de utilidad agregado.", color: "success", show: true } });
		},

		async marginItemUpdate({ commit, dispatch }, itemToUpdate) {
			commit("marginItemUpdate", itemToUpdate.data ? itemToUpdate.data : itemToUpdate);
			dispatch("putMargins", itemToUpdate.relational);
			commit("setState", { field: "snackBar", data: { text: "Porcentaje de utilidad actualizado.", color: "success", show: true } });
		},

		async marginItemDelete({ commit, dispatch }, itemsToDelete) {
			commit("marginItemDelete", itemsToDelete.data ? itemsToDelete.data : itemsToDelete);
			dispatch("putMargins", itemsToDelete.relational);
			commit("setState", {
				field: "snackBar",
				data: {
					text: "Porcentaje de utilidad eliminado.",
					color: "success",
					show: true,
				},
			});
		},

		async getMargins({ state, commit, dispatch }, relational = true) {
			if (state.appConfig.isReseller) {
				try {
					const resMargins = await axios.post("/margin/read.php", JSON.stringify({ token: state.token, customerId: state.account.id, relational: relational }));
					if (resMargins.data.length > 0) {
						commit("setState", { field: "margins", data: resMargins.data.sort(firstBy("item_name").thenBy("brand_name").thenBy("subitem_name")) });
					} else {
						dispatch("initializeMargins", 40);
					}
					return state.margins;
				} catch (err) {
					console.log("** ERROR ** Get Margins", err);
				}
			} else {
				dispatch("initializeMargins", 0);
			}
			return [];
		},

		async putMargins({ state, commit, dispatch }, relational = true) {
			try {
				const resMargins = await axios.post("/margin/put.php", JSON.stringify({ token: state.token, customerId: state.account.id, margins: state.margins }));
				if (resMargins.data.message == "ok") {
					// commit("setState", { field: "snackBar", data: {text: "Porcentaje de utilidad agregado.", color: "success", show: true}});
					dispatch("registerEvent", { code: "MARGINSAVE", info: null });
				} else {
					console.log("** ERROR ** Put Margins");
					commit("setState", { field: "snackBar", data: { text: "No se pudo guardar el porcentaje de utilidad.", color: "error", show: true } });
				}
			} catch (err) {
				console.log("** ERROR ** Put Margins", err);
				commit("setState", { field: "snackBar", data: { text: "ERROR al guardar el porcentaje de utilidad.", color: "error", show: true } });
			} finally {
				dispatch("getMargins", relational);
			}
		},

		async getFlyers({ state }) {
			try {
				const resFlyers = await axios.post("/flyer/read.php", JSON.stringify({ token: state.token, customerId: state.account.id }));
				if (resFlyers.data.length > 0) {
					// newFlyers = resFlyers.data; //.sort(firstBy("date_from").thenBy("brand_name"));
					return resFlyers.data.map((i) => {
						if (state.business.isDemo) {
							i.fileUrl = `https://picsum.photos/1080/1920?image=${i.id * 5 + 10}`;
							i.title = "Título en breves palabras";
							i.info = "Información del folleto en pocas palabras";
						} else {
							// i.fileUrl = 'https://oechlse.osapp.com.ar/backend/doc/flyers/' + i.fileUrl;
							i.fileUrl = "/backend/doc/flyers/" + i.fileUrl;
						}
						return i;
					});
				}
			} catch (e) {
				console.log("** ERROR ** Get Flyers", e);
			}
			return [];
		},

		saveConfig({ commit, dispatch }, newConfigData) {
			commit("setState", { field: "viewConfig", data: newConfigData });
			dispatch("saveOne", "viewConfig");
		},

		async getApplicationsByArticleId({ state }, article_id) {
			try {
				const results = await axios.post(
					"/aplapplication/read.php",
					JSON.stringify({ token: state.token, customerId: state.account.id, target: "AplApplication", articuloId: article_id })
				);
				if (results.data) {
					return results.data.sort(firstBy("brand_name").thenBy("model_name").thenBy("version_name"));
				}
			} catch (error) {
				console.log("** ERROR ** Get Applications by Article", err);
			}
			return [];
		},

		async getEquivalentsByArticleId({ state }, article_id) {
			try {
				const results = await axios.post("/articulo/other.php", JSON.stringify({ token: state.token, clientId: state.account.id, articuloId: article_id }));
				if (results.data) {
					return results.data.sort(firstBy("brand_name"));
				}
			} catch (err) {
				console.log("** ERROR ** Get Equivalents by Article", err);
			}
			return [];
		},

		async getTechnicalsByArticleId({ state }, article_id) {
			try {
				const results = await axios.post("/articulo/read.php", JSON.stringify({ token: state.token, target: "technicals", id: article_id }));
				if (results.data) {
					return results.data;
				}
			} catch (err) {
				console.error("ERROR descargando Technicals", err);
			}
			return [];
		},

		getSurveys({ state, commit }) {
			if (state.token && state.business.enableSurveys) {
				try {
					axios
						.post("/survey/read.php", JSON.stringify({ token: state.token, customerId: state.account.id }))
						.then((res) => {
							commit("setState", { field: "surveyData", data: res.data && res.data.length > 0 ? res.data : null });
							if (state.surveyData !== null) {
								setTimeout(
									() => {
										commit("setState", { field: "showSurvey", data: true });
									},
									this.getters.isDeveloping ? 5000 : Math.floor(Math.random() * 30) * 60000
								);
							}
						})
						.catch((error) => {
							console.log("** ERROR ** Get Surveys", error);
						});
				} catch (err) {
					console.log("** ERROR ** Get Surveys", err);
				}
			}
		},

		async readShippingAddress({ state }) {
			let custAddress = [];
			let newAddress = [
				{
					id: 0,
					customer_id: 0,
					shipping_name: "Otro (Agregar nuevo domicilio)",
					shipping_type: "ADD",
					contact: "",
					zip_code: "",
					state_name: "",
					city_name: "",
					street_name: "",
					street_number: "",
					street_department: "",
					street_one: "",
					street_two: "",
					mobile_phone: "",
					comment: "",
					is_default: 0,
					created: null,
					modified: null,
					deleted: null,
				},
			];
			newAddress.customer_id = state.account.code;
			try {
				const resAddress = await axios.post("/shipping/read.php", JSON.stringify({ token: state.token, customerId: state.account.id }));
				if (resAddress.data.length > 0) {
					custAddress = resAddress.data;
				}
			} catch (e) {
				console.log("** ERROR ** Get Address", e);
			}
			return custAddress;
		},

		async saveShippingAddress({ state, commit, dispatch }, data) {
			data.is_default = data.is_default ? 1 : 0;
			data.for_invoice = data.for_invoice ? 1 : 0;
			try {
				const resSave = await axios.post("/shipping/save.php", JSON.stringify({ token: state.token, customerId: state.account.id, addresses: data }));
				if (resSave.data.message == "ok") {
					commit("setState", { field: "snackBar", data: { text: "Direcciones actualizadas.", color: "success", show: true } });
					const adrs = await dispatch("readShippingAddress");
					commit("setState", { field: "shippingAddresses", data: adrs });
				} else {
					commit("setState", { field: "snackBar", data: { text: "No se pudo actualizar direcciones.", color: "error", show: true } });
				}
			} catch (e) {
				console.log("** ERROR ** Save Address", e);
				commit("setState", { field: "snackBar", data: { text: "ERROR al guardar las direcciones.", color: "error", show: true } });
			}
		},

		async removeShippingAddress({ state, commit, dispatch }, addressId) {
			try {
				const resDelete = await axios.post("/shipping/delete.php", JSON.stringify({ token: state.token, customerId: state.account.id, addressId: addressId }));
				if (resDelete.data.message == "ok") {
					commit("setState", { field: "snackBar", data: { text: "Direcciones actualizadas.", color: "success", show: true } });
					// commit("setState", { field: "shippingAddresses", data: data.addresses });
				} else {
					// commit("setState", { field: "snackBar", data: {text: "No se pudieron actualizar las direcciones.", color: "error", show: true,},});
				}
			} catch (e) {
				console.log("** ERROR ** remove Address", e);
			}
		},

		async getParametersValues({ state, commit }, netWeight = -1) {
			if (state.token) {
				try {
					const res = await axios.post("/params/read.php", JSON.stringify({ token: state.token, customerId: state.account.id, netWeight }));
					if (res.data && !res.data.error) {
						// console.log("parms:", res.data)
						// SHIPMENT_AMOUNT
						commit("setShipmentAmount", res.data.shippingCost.price);
						// FREE_SHIPPING_FROM
						commit("setFreeShippingFrom", res.data.freeShippingFrom);
						// ORDER_MINIMUM_AMOUNT
						commit("setOrderMinimumAmount", res.data.orderMinimumAmount);
					} else {
						console.log("NODATA");
					}
				} catch (e) {
					commit("setShipmentAmount", 0);
					commit("setFreeShippingFrom", 0);
					commit("setOrderMinimumAmount", 0);
					console.log("** ERROR ** Get Parameters", e);
				}
			}
		},

		// fin actions ##################################################################
	},

	// INFO: GETTERS ##################################################################
	getters: {
		isAuthenticated: (state) => !!state.token && state.uname !== "invitado",

		isDeveloping: () => window.location.href.includes("localhost") || window.location.href.includes("app.osapp"),

		isMobile: () => window.screen.orientation.type == "portrait-primary" && window.screen.availWidth <= 768,

		paymentsUI: (state) => state.business.enablePayments,

		getStockIconAndColor: (state) => (item) => {
			const stockIconsAndColors = [
				{ value: 0, icon: "mdi-battery-low", color: "red" },
				{ value: 1, icon: "mdi-battery-medium", color: "amber" },
				{ value: 2, icon: "mdi-battery-high", color: "green" },
				{ value: 3, icon: "mdi-battery-sync-outline", color: "blue" },
			];

			if (!item) {
				return stockIconsAndColors[0];
			} else {
				if (+item.stock <= 0 && +item.cant_eqv > 0) {
					let alters = state.alternatives.find((alter) => alter.id === item.id);
					if (alters) {
						alters.articles.map((cual) => {
							let found = state.products.find((pr) => pr.id == cual.id && pr.stock > 0);
							if (found) {
								return stockIconsAndColors[3];
							}
						});
					}
				}
				return stockIconsAndColors[+item.stock];
			}
		},

		recalculatePrices: () => (prods) => {
			const recalculated = prods.map((item) => {
				item.article.price = +item.article.price;
				item.article.discount = +item.article.discount;
				item.article.margin = +item.article.margin || this.margins[0].margin;
				item.article.tax = +item.article.tax || 0;
				item.article.extra_discount = +item.article.extra_discount;
				let costExtra = +item.article.price * (1 - +item.article.discount / 100) * (1 - +item.article.extra_discount / 100);
				item.article.priceCost = +costExtra.toFixed(2);
				return item;
			});
			return recalculated;
		},

		// fin getters ##################################################################
	},
});
