// import '@mdi/font/css/materialdesignicons.css';
import Vue from 'vue';
import Vuetify from 'vuetify';
import es from 'vuetify/es5/locale/es';
import 'vuetify/dist/vuetify.min.css';
import colors from 'vuetify/lib/util/colors';
// import colors from 'vuetify/es5/util/colors';

Vue.use(Vuetify);

// CODEFLEX DEMOS

export default new Vuetify({
	theme: {
		options: {
			customProperties: true,
		},
		themes: {
			light: {
				primary: colors.blue.darken3,	// #37474F #6D4C41
				secondary: colors.lightBlue.darken3,	// #F9A825 #FFB300
				accent: colors.red.accent3,		// #FF6D00 #FF5722
				featured: colors.amber.lighten5,	// #FFF8E1
				// error:		#FF5252
				// warning:	#FFC107
				// info:		#2196F3
				// success:	#4CAF50
			},
			// default: {
				// primary: colors.lightBlue.darken2,		// #0288D1
				// secondary: colors.blueGrey.lighten1,	// #78909C
				// accent: colors.lightBlue.accent2,		// #40C4FF
			// },
		},
	},
	lang: {
		locales: { es },
		current: 'es',
	},
	icons: {
		iconfont: 'mdi'
	}
	
});
