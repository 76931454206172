import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import axios from 'axios';
import VueAxios from 'vue-axios';
import uuid from 'vue-uuid';

import "roboto-fontface/css/roboto/roboto-fontface.css"; // new
import "@mdi/font/css/materialdesignicons.css"; // new

import './registerServiceWorker';

Vue.use(VueAxios, axios, uuid)
// Vue.use(VueGtag, 
// 	{ config: { id: 'G-MJKWK8Z8FQ', params: { send_page_view: false } } },
// 	router
// );

// axios.defaults.baseURL = store.getters.isDeveloping ? 'https://cbk.osapp.com.ar/backend/api/' : '/backend/api/';
axios.defaults.baseURL = store.getters.isDeveloping ? 'http://localhost/osapp/api/' : '/backend/api/';

Vue.config.productionTip = false

new Vue({
	router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
